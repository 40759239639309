<template>
  <div>
    <nav-header :title="header_title"></nav-header>
    
    <div class="paper">
      <div class="pic">
        <img :src="banner" style="width:100%;height:100%;">
      </div>
    </div>
    <div style="font-weight: bold;padding: 10px 0;padding-left: 18px;background-color: #f4f4f4;">
      {{ title }}
    </div>

    <div class="signinPage">
      <div class="cell">
        <div class="l">姓名：</div>
        <div class="r">
          <input type="text" v-model="form.name">
          <div class="err" v-if="nameErr">请输入姓名</div>
        </div>
      </div>
      <div class="cell">
        <div class="l">身份证号：</div>
        <div class="r">
          <input type="text" v-model="form.idNum" maxlength="18">
          <div class="err" v-if="idErr">身份证格式有误</div>
        </div>
      </div>
      <div class="cell">
        <div class="l">学生证号：</div>
        <div class="r">
          <input type="text" v-model="form.stuNum" maxlength="20">
        </div>
      </div>
      <div class="cell">
        <div class="l">手机号码：</div>
        <div class="r">
          <input type="text" v-model="form.mobile" maxlength="11">
          <div class="err" v-if="mobileErr">手机号格式有误</div>
        </div>
      </div>
    </div>

    <div class="signSubmit" @click="submit">提交</div>
  </div>
</template>
  
<script>
  import NavHeader from "../../components/nav-header";
  export default {
    components: {
      NavHeader,
    },
    data() {
      return {
        header_title: "活动报名",
        form: {
          name: "",
          idNum: null,
          stuNum: null,
          mobile: null,
        },
        id: 0,
        idErr: false,
        mobileErr: false,
        nameErr: false,
        banner: {}
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.banner = this.$route.query.img
      this.title = this.$route.query.title
      console.log('ididid', this.id)
    },
    methods: {
      submit() {
        const {form} = this
        // const valid =  this.checkForm()
        const valid =  true
        if(valid) {
          let params = {
            activityId: this.id,
            userId: this.$store.state.user.id,
            realName: form.name,
            idCardNumber: form.idNum,
            studentIdNumber: form.stuNum,
            mobile: form.mobile
          }
          this.$api.Activity.Register(params).then((res)=>{
            if(res.code === 0) {
              this.$message.success("报名成功");
              setTimeout(()=>{
                this.$router.back();
              },1000)
            }
          })
        }
      },
      checkForm() {
        const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
        this.idErr = !regId.test(this.form.idNum)
        const regMob = /^1[3-9]\d{9}$/
        this.mobileErr = !regMob.test(this.form.mobile)
        if(!this.form.name) {
          this.nameErr = true
        } else {
          this.nameErr = false
        }
        
        if(!this.idErr && !this.mobileErr && this.form.name && this.form.stuNum) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>
  
<style>
  .signinPage {
    padding: 20px;
    display: flex;
    flex-direction: column;
    padding-top: 55px;
  }

  .cell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .cell>.r>input {
    text-align: right;
  }

  .signSubmit {
    width: 90%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    color: #FFF;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #2e7bfd;
    border-radius: 8px;
  }

  .err {
    color: red;
    font-size: 10px;
  }
</style>